


























import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'RemoveBids',
  components: {
    SfButton,
  },
  props: {
    selectedItems: {
      type: Array as PropType<number[]>,
      required: true,
    },
  },
  setup (props) {
    const confirmationButtonText = computed(() => {
      return props.selectedItems.length > 1 ? 'Remove bids' : 'Remove bid';
    });
    const confirmationText = computed(() => {
      return props.selectedItems.length > 1 ?
        'Are you sure you want to remove the selected bids? You can always place a bid at another time.' :
        'Are you sure you want to remove the selected bid? You can always place a bid at another time.';
    });

    return {
      confirmationButtonText,
      confirmationText,
    };
  },
});
