import { render, staticRenderFns } from "./RemoveBids.vue?vue&type=template&id=7b8e0b23"
import script from "./RemoveBids.vue?vue&type=script&lang=ts"
export * from "./RemoveBids.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/var/www/modules/amplience/components/content-type/Header.vue').default})
